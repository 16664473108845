<template>
    <div class="font">
        <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="breadcrumb-move"
        >
            <el-breadcrumb-item :to="{ path: '/welcome' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>物视镜</el-breadcrumb-item>
            <el-breadcrumb-item>购买入库</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card">
            <el-form
                
                :model="setInCabinetForm"
                :rules="rules"
                ref="ruleForm"
                label-width="100px"
                class="demo-ruleForm"
            >
            <el-row>
  <el-col :span="8"><div class="grid-content bg-purple"><el-form-item label="申请人" prop="name">
                    <el-tag>
                        {{ this.username }}
                    </el-tag>
                </el-form-item></div></el-col>
  <el-col :span="8"><div class="grid-content bg-purple-light"><el-form-item label="申请时间" required>
                    <el-date-picker
                        v-model="valueTime"
                        type="datetime"
                        placeholder="选择日期时间"
                        default-time="12:00:00"
                    >
                    </el-date-picker>
                </el-form-item></div></el-col>
  <el-col :span="8"><div class="grid-content bg-purple"></div></el-col>
</el-row>
                

                
                <el-form-item label="入库明细" required label-position="top">
                    <el-table border :data="form.infoList" style="width: 100%">
                        <el-table-column
                            prop="id"
                            label="序号"
                            width="50"
                            type="index"
                            align="center"
                            :index="(index) => index + 1"
                        />
                        <el-table-column
                            prop="medicalName"
                            label="药品名称"
                            align="center"
                        >
                            <template #default="scope">
                                <span v-show="!scope.row.editFlag">{{
                                    scope.row.medicalName
                                }}</span>
                                <el-input
                                    v-show="scope.row.editFlag"
                                    v-model="scope.row.medicalName"
                                >
                                </el-input>
                            </template>
                        </el-table-column>

                        <el-table-column
                            prop="specification"
                            label="规格"
                            align="center"
                        >
                            <template #default="scope">
                                <span v-show="!scope.row.editFlag">{{
                                    scope.row.specification
                                }}</span>
                                <el-input
                                    v-show="scope.row.editFlag"
                                    v-model="scope.row.specification"
                                >
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="unit"
                            label="单位"
                            align="center"
                        >
                            <template #default="scope">
                                <span v-show="!scope.row.editFlag">{{
                                    scope.row.unit
                                }}</span>
                                <el-input
                                    v-show="scope.row.editFlag"
                                    v-model="scope.row.unit"
                                >
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="quantity"
                            label="数量"
                            align="center"
                        >
                            <template #default="scope">
                                <span v-show="!scope.row.editFlag">{{
                                    scope.row.quantity
                                }}</span>
                                <el-input
                                    v-show="scope.row.editFlag"
                                    v-model="scope.row.quantity"
                                >
                                </el-input>
                            </template>
                        </el-table-column>
                        <!--
                            <el-table-column
                            prop="stokeVolume"
                            label="库存量"
                            align="center"
                        >
                            <template #default="scope">
                                <span v-show="!scope.row.editFlag">{{
                                    scope.row.stokeVolume
                                }}</span>
                                <el-input
                                    v-show="scope.row.editFlag"
                                    v-model="scope.row.stokeVolume"
                                >
                                </el-input>
                            </template>
                        </el-table-column>
                        -->

                        <el-table-column
                            prop="cabinetId"
                            label="物品柜"
                            align="center"
                        >
                            <template #default="scope">
                                <span v-show="!scope.row.editFlag">{{
                                    scope.row.cabinetId
                                }}</span>
                                <el-input
                                    v-show="scope.row.editFlag"
                                    v-model="scope.row.cabinetId"
                                >
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="notAllowedMix"
                            label="禁配物"
                            align="center"
                        >
                            <template #default="scope">
                                <span v-show="!scope.row.editFlag">{{
                                    scope.row.notAllowedMix
                                }}</span>
                                <el-input
                                    v-show="scope.row.editFlag"
                                    v-model="scope.row.notAllowedMix"
                                >
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            prop="emergencyMeasures"
                            label="急救措施"
                            align="center"
                        >
                            <template #default="scope">
                                <span v-show="!scope.row.editFlag">{{
                                    scope.row.emergencyMeasures
                                }}</span>
                                <el-input
                                    v-show="scope.row.editFlag"
                                    v-model="scope.row.emergencyMeasures"
                                >
                                </el-input>
                            </template>
                        </el-table-column>
                        <el-table-column
                            label="操作"
                            width="120"
                            align="center"
                        >
                            <template #default="scope">
                                <div style="display: flex">
                                    <el-button
                                        size="small"
                                        type="text"
                                        icon="CircleCheck"
                                        v-show="scope.row.editFlag"
                                        @click="submit(scope.row)"
                                        >确定
                                    </el-button>
                                    <el-button
                                        size="small"
                                        type="text"
                                        icon="CircleClose"
                                        v-show="scope.row.editFlag"
                                        @click="cancel(scope.row, scope.$index)"
                                        >取消
                                    </el-button>
                                    <el-button
                                        size="small"
                                        icon="Edit"
                                        type="text"
                                        v-show="!scope.row.editFlag"
                                        @click="edit(scope.row)"
                                    >
                                        编辑
                                    </el-button>
                                    <el-button
                                        size="small"
                                        icon="Delete"
                                        type="text"
                                        v-show="!scope.row.editFlag"
                                        @click="del(scope.row.$index)"
                                        >删除
                                    </el-button>
                                </div>
                            </template>
                        </el-table-column>
                    </el-table>
                    <div style="width: 100%">
                        <el-button
                            @click="add"
                            type="text"
                            size="small"
                            icon="CirclePlus"
                            >+ 点击添加</el-button
                        >
                    </div>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="submitForm('ruleForm')"
                        >立即创建</el-button
                    >
                    <el-button @click="resetForm('ruleForm')">重置</el-button>
                </el-form-item>
            </el-form>
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            username: sessionStorage.getItem("username"),
            form: {
                infoList: [],
            },
            pickerOptions: {
                shortcuts: [
                    {
                        text: "今天",
                        onClick(picker) {
                            picker.$emit("pick", new Date());
                        },
                    },
                    {
                        text: "昨天",
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24);
                            picker.$emit("pick", date);
                        },
                    },
                    {
                        text: "一周前",
                        onClick(picker) {
                            const date = new Date();
                            date.setTime(date.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit("pick", date);
                        },
                    },
                ],
            },
            valueTime: "",
            setInCabinetForm: {
                name: "",
                time: "",
                chemicalList: [],
            },
            ruleForm: {
                name: "",
                region: "",
                date1: "",
                date2: "",
                delivery: false,
                type: [],
                resource: "",
                desc: "",
            },
            rules: {
                name: [
                    {
                        required: true,
                        message: "请输入活动名称",
                        trigger: "blur",
                    },
                    {
                        min: 3,
                        max: 5,
                        message: "长度在 3 到 5 个字符",
                        trigger: "blur",
                    },
                ],
                region: [
                    {
                        required: true,
                        message: "请选择活动区域",
                        trigger: "change",
                    },
                ],
                date1: [
                    {
                        type: "date",
                        required: true,
                        message: "请选择日期",
                        trigger: "change",
                    },
                ],
                date2: [
                    {
                        type: "date",
                        required: true,
                        message: "请选择时间",
                        trigger: "change",
                    },
                ],
                type: [
                    {
                        type: "array",
                        required: true,
                        message: "请至少选择一个活动性质",
                        trigger: "change",
                    },
                ],
                resource: [
                    {
                        required: true,
                        message: "请选择活动资源",
                        trigger: "change",
                    },
                ],
                desc: [
                    {
                        required: true,
                        message: "请填写活动形式",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    mounted() {
        // 删除
    },
    methods: {
        /************************************************************* */
        //点击添加
        add() {
            this.form.infoList.push({
                editFlag: true, // 可编辑标识
                isSubmit: false, // 是否点击确定标识
            });
        },
        //删除表格项
        del(index) {
            this.form.infoList.splice(index, 1);
        },
        // 编辑
        editVersion(row) {
            v.value = JSON.parse(JSON.stringify(row));
            row.editFlag = true;
        },
        // 取消
        cancel(row, index) {
            row.editFlag = false;
            if (row.isSubmit) {
                this.form.infoList[index] = v.value;
            } else {
                this.del(index);
            }
        },
        // 确定
        submit(row) {
            row.editFlag = false;
            row.isSubmit = true;
        },
        /***************************************************************** */
        submitForm(formName) {
            let username = sessionStorage.getItem("username");
            this.setInCabinetForm.chemicalList = this.form.infoList;
            this.setInCabinetForm.time = this.valueTime;
            this.setInCabinetForm.name = username;
            let cabinetFormList = this.setInCabinetForm;
            console.log(cabinetFormList);
            this.$http.post("login", this.setInCabinetForm);
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
    },
};
</script>
<style lang="less" scoped>
.font {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    .box-card {
        width: 100%;
        margin-top: 13px;
        border-radius: 8px;
    }
    .el-card__body,
    .el-main {
        height: 100%;
    }
    .index {
        font-size: 230px;
        font-weight: 800;
    }
}
</style>
