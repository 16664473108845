<template>
    <div>
        <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="breadcrumb-move"
        >
            <el-breadcrumb-item :to="{ path: '/welcome' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>数据汇总</el-breadcrumb-item>
            <el-breadcrumb-item>操作记录</el-breadcrumb-item>

        </el-breadcrumb>
        <el-card class="box-card">
            <el-table
                :data="tableData"
                style="width: 100%"
                :row-class-name="tableRowClassName"
                border
                size="mini"
                height="550"
            >
                <el-table-column
                    prop="startTime"
                    label="时间"
                    width="180"
                    sortable
                >
                </el-table-column>
                <el-table-column
                    prop="location"
                    label="地点"
                    width="180"
                    sortable
                >
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="用户"
                    width="180"
                    sortable
                >
                </el-table-column>
                <el-table-column prop="do" label="设备"> </el-table-column>
                <el-table-column
                    prop="time"
                    label="操作"
                    sortable
                ></el-table-column>
                <el-table-column
                    prop="state"
                    label="结果"
                    sortable
                ></el-table-column>
            </el-table>
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="1"
                :page-size="14"
                layout="total,  prev, pager, next, jumper"
                :total="154"
                style="margin-top:20px"
                align="center"
            >
            </el-pagination>
        </el-card>
    </div>
</template>
<script>
import moment from "moment";
export default {
    data() {
        return {
            tableData: [],
        };
    },
    methods: {
        handleCurrentChange(val) {
            this.getInfo()
        },
        tableRowClassName({ row, rowIndex }) {
            if (row.state == "按时归还") {
                return "success-row";
            } else if (row.state == "未归还（逾期）") {
                return "error-row";
            } else if (row.state == "已归还（逾期）") {
                return "warning-row";
            } else if (row.state == "正在进行") {
                //return "warning-row";
            }
            return "";
        },
        getRandomUse() {
            let use = [
                "MacOS Chrome",
                "MacOS应用",
                "Windows应用",
                "Windows edge",
                "Linux FireFox",
                "MacOS Safari",
                "Windows 360浏览器"
            ];
            return use[Math.floor(Math.random() * use.length)];
        },
        getLocation(){
            let name = [
                "南京",
                "苏州",
                "美国-得克萨斯州",
                "美国-圣何塞",
                "新西兰",
            ];
            return name[Math.floor(Math.random() * name.length)];
        },
        getRandomName() {
            let name = [
                "汤宗朔",
                "沈浩",
            ];
            return name[Math.floor(Math.random() * name.length)];
        },
        getRandomState() {
            let state = [
                "失败",
                "成功",
                "正在发送请求"
            ];
            return state[Math.floor(Math.random() * state.length)];
        },
        getRandomTime(month) {
            // 随机生成0-11的数字
            const randomMonthNum = Math.floor(Math.random * 11);
            // 随机生成1-30数字
            const randomDateNum = Math.ceil(Math.random() * 30);
            // 随机生成1-24 数字
            const randomHourNum = Math.ceil(Math.random() * 24);
            // 随机生成1-60 数字
            const randomMinuteNum = Math.ceil(Math.random() * 60);

            return moment()
                .month(month)
                .date(randomDateNum)
                .hour(randomHourNum)
                .minutes(randomMinuteNum)
                .seconds(randomMinuteNum)
                .format("YYYY-MM-DD HH:mm:ss");
        },
        getInfo() {
            this.tableData=[]
            for (let i = 0; i < 14; i++) {
                let temp = {
                    startTime: this.getRandomTime(3),
                    location:this.getLocation(),
                    name: this.getRandomName(),
                    do: this.getRandomUse(),
                    time: "--",
                    state: this.getRandomState(),
                };
                this.tableData.push(temp);
            }
            for (let i = 0; i < this.tableData.length; i++) {
                this.tableData[i].time = (
                    (Date.parse(this.tableData[i].endTime) -
                        Date.parse(this.tableData[i].startTime)) /
                    (1000 * 60 * 60 * 24)
                ).toFixed(1);
            }
            //console.log(temp);
        },
    },
    created() {
        this.getInfo();
    },
};
</script>
<style>
.el-table .warning-row {
    background: #e6a23cd0;
    color: black;
}

.el-table .success-row {
    background: #67c23ad0;
    color: black;
}
.el-table .error-row {
    background: #f56c6cd0;
    color: black;
}
</style>