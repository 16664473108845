import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Home from '../views/Home.vue'
import topHome from '../views/topMenu/topHome.vue'
import welcome from '../views/welcome.vue'
import AdminList from '../views/User/AdminList.vue'
import MemberList from '../views/User/MemberList.vue'
import securityShow from '../views/security-threejs/securityShow.vue'
import fullScreen from '../views/security-threejs/fullScreen.vue'
import personal from '../views/topMenu/personal.vue'
import workBench from '../views/topMenu/workBench.vue'
import message from '../views/topMenu/message.vue'
import handel from '../views/topMenu/handel.vue'
import teamList from '../views/User/teamList.vue'
import roleList from '../views/role/roleList.vue'
import myCabinet from '../views/cabinet/myCabinet.vue'
import addChemical from '../views/chemical/addCemical.vue'
import inList from '../views/list/in.vue'
import outList from '../views/list/out.vue'
import mainList from '../views/list/main.vue'
import doList from '../views/list/do.vue'
import borrow from '../views/chemical/borrow.vue'
import VueWechatTitle from 'vue-wechat-title'
import powerList from '../views/list/changeRole.vue'
import roleListMain from '../views/list/changeRoleList.vue'

Vue.use(VueWechatTitle)
Vue.use(VueRouter)

// 解决编程式路由往同一地址跳转时会报错的情况
const originalPush = VueRouter.prototype.push;
const originalReplace = VueRouter.prototype.replace;

// push
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
//replace
VueRouter.prototype.replace = function push(location, onResolve, onReject) {
    if (onResolve || onReject)
        return originalReplace.call(this, location, onResolve, onReject);
    return originalReplace.call(this, location).catch(err => err);
};


const router = new VueRouter({
    routes: [
        {
            path: '/',
            redirect: '/login',
            meta: {
                title: "数室安全全生命周期管理管理系统"
            }
        },
        {
            path: '/login',
            component: Login,
            meta: {
                title: "数室安全全生命周期管理管理系统"
            }
        },
        {
            path: '/404-notFound',
            name: '404',
            component: () => import('../404.vue'),
            meta: {
                title: "数室安全-404"
            }
        },
        {
            path: '/home',
            component: Home,
            redirect: '/welcome',
            children: [
                {
                    path: '/welcome',
                    component: welcome,
                    meta: {
                        title: "数室安全-首页"
                    }
                },
                {
                    path: '/adminList',
                    component: AdminList,
                    meta: {
                        title: "数室安全-管理员列表"
                    }
                },
              {
                path: '/memberList',
                component: MemberList,
                meta: {
                  title: "数室安全-成员列表"
                }
              },
              {
                path: '/teamList',
                component: teamList,
                meta: {
                  title: "数室安全-团队列表"
                }
              },
              {
                path: '/BIMmodel',
                component: securityShow,
                meta: {
                  title: "数室安全-安全学院模型预览"
                }
              },
              {
                path: '/roleList',
                component: roleList,
                meta: {
                  title: "数室安全-角色列表"
                }
              },
              {
                path: '/myCabinet',
                component: myCabinet,
                meta: {
                  title: "数室安全-我的物品柜"
                }
              },
              {
                path: '/purchaseIn',
                component: addChemical,
                meta: {
                  title: "数室安全-药品入库"
                }
              },
              {
                path: '/in-list',
                component: inList,
                meta: {
                  title: "数室安全-入库记录"
                }
              },
              {
                path: '/out-list',
                component: outList,
                meta: {
                  title: "数室安全-借出记录"
                }
              },
              {
                path: '/do-list',
                component: doList,
                meta: {
                  title: "数室安全-操作日志"
                }
              },
              {
                path: '/main-list',
                component: mainList,
                meta: {
                  title: "数室安全-物品库存"
                }
              },
              {
                path: '/borrow',
                component: borrow,
                meta: {
                  title: "数室安全-物品借用"
                }
              },
              {
                path: '/roleList',
                component: roleListMain,
                meta: {
                  title: "数室安全-角色列表"
                }
              },
              {
                path: '/powerList',
                component: powerList,
                meta: {
                  title: "数室安全-权限列表"
                }
              },

            ]
        },
        {
            path: '/top-home',
            component: topHome,
            redirect:'/personal',
            children:[
                {
                    path: '/personal',
                    component: personal,
                    meta: {
                        title: "数室安全-个人中心"
                    }
                },
                {
                    path: '/workBench',
                    component: workBench,
                    meta: {
                        title: "数室安全-工作台"
                    }
                },
                {
                    path: '/message',
                    component: message,
                    meta: {
                        title: "数室安全-消息中心"
                    }
                },
                {
                    path: '/handel',
                    component: handel,
                    meta: {
                        title: "数室安全-处理中心"
                    }
                },
            ],

        },

        {
            path: '/BIMShow',
            component: fullScreen,
            meta: {
                title: "数室安全-安全学院数字孪生大屏",
            }
        },
        {
            path: '*', // 此处需特别注意至于最底部
            redirect: '/404-notFound'
        }
    ]
})
/*
router.beforeEach((to, from, next) => {
    //to表示将要访问的路径
    //from表示从哪个路径跳转来
    //next为一个函数，表示放行
    //next() 放行   next('/....') 表示强制跳转
    if (to.path === '/login') return next();
    let tokenStr = sessionStorage.getItem('token')
    if (!tokenStr) return next('/login')

    //路由发生变化时候修改页面中的title
    if (to.meta.title) {
        document.title = to.meta.title

        if (!to.matched.length) {
            const storeMenus = localStorage.getItem("token")
            if (storeMenus) {
                //如果有token内容，说明已经登录过，跳到404
                next("/404-notFound") // 跳到
            } else {
                //如果没有token内容，说明还没有登录过
                next("/login")
            }
        }
        //找到了就放行路由
        next(true)//放行路由
    }
    next()
}) */
//挂载路由导航守卫



export default router
