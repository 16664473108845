import Vue from 'vue'
import App from './App.vue'
import router from './router'
import './plugins/element.js'
//引入nprogress
import NProgress from 'nprogress'
import moment from 'moment';
import 'nprogress/nprogress.css' //这个样式必须引入
//导入全局css
import './assets/css/global.css'

//导入axios发起请求
import axios from 'axios'
NProgress.configure({     
    easing: 'ease',  // 动画方式    
    speed: 500,  // 递增进度条的速度    
    showSpinner: false, // 是否显示加载ico    
    trickleSpeed: 500, // 自动递增间隔    
    minimum: 0.3 // 初始化时的最小百分比
})

//用户端设备检测
Vue.prototype.$equipment=function(){
    let flag = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
   return flag;
}

//配置请求根路径
//vue.config.js修改跨域问题
axios.defaults.baseURL = 'https://xiangzhi.api.yueyuezi.com/'

//将token值写入请求头
axios.interceptors.request.use(config => {
    config.headers.satoken = sessionStorage.getItem('token')
    // 每次切换页面时，调用进度条
    NProgress.start();
    // 这个一定要加，没有next()页面不会跳转的。这部分还不清楚的去翻一下官网就明白了
    //next();
    //return不可省略
    return config
})

  //response中完成进度条Nprogress.done()
  axios.interceptors.response.use(config => {
  	  //服务响应时完成进度条
  	  NProgress.done()
  	  return config
  })

Vue.prototype.$http = axios


Vue.config.productionTip = false

new Vue({
    router,
    render: h => h(App)
}).$mount('#app')
