<template>
    <div class="font">
        <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="breadcrumb-move"
        >
            <el-breadcrumb-item :to="{ path: '/welcome' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>物视镜</el-breadcrumb-item>
            <el-breadcrumb-item>我的物品柜</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="cabinet-card">
            <el-row>
                <el-col :span="8"
                    ><div class="grid-content bg-purple">
                        <el-dropdown>
                            <el-button type="primary" plain size="small">
                                更多操作<i
                                    class="el-icon-arrow-down el-icon--right"
                                ></i>
                            </el-button>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item
                                    ><el-button
                                        icon="el-icon-info"
                                        type="text"
                                        @click="cabinetTypeAll()"
                                        >查看分类</el-button
                                    ></el-dropdown-item
                                >
                                <el-dropdown-item
                                    ><el-button
                                        icon="el-icon-circle-plus"
                                        type="text"
                                        @click="addCabinet()"
                                        >添加物品柜</el-button
                                    ></el-dropdown-item
                                >
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div></el-col
                >

                <el-col :span="10"
                    ><div class="grid-content bg-purple-light">
                        <el-tag type="info" class="total-number" >
                            物品柜总数：共 {{ this.totalCabinet }} 个
                        </el-tag>
                    </div></el-col
                >
                <el-col :span="6"
                    ><el-input
                        placeholder="请输入搜索内容"
                        class="input-with-select"
                        v-model="searchCabinet"
                        size="small"
                    >
                        <el-button
                            slot="append"
                            icon="el-icon-search"
                            @click="searchCabinet()"
                        ></el-button> </el-input
                ></el-col>
            </el-row>
        </el-card>

        <el-card class="box-card" v-for="item in cabinetList" :key="item.id">
            <el-descriptions
                class="margin-top"
                :title="item.cabinetName"
                :column="3"
                size="mini"
                border
            >
                <template slot="extra">
                    <el-button
                        type="primary"
                        size="mini"
                        @click="openCabinetList(item.id)"
                        >查看物品</el-button
                    >
                    <el-button
                        type="warning"
                        size="mini"
                        @click="updateCabinet(item)"
                        >更新信息</el-button
                    >
                    <el-button
                        type="danger"
                        size="mini"
                        @click="deleteCabinet(item.id)"
                        >删除物品柜</el-button
                    >
                </template>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-user"></i>
                        负责人
                    </template>
                    {{ item.leaderName }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-mobile-phone"></i>
                        联系方式
                    </template>
                    {{ item.leaderPhoneNumber }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-message"></i>
                        邮箱
                    </template>
                    {{ item.leaderEmail }}
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-tickets"></i>
                        分类
                    </template>
                    <el-tag size="small">{{ item.cabinetType }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-tickets"></i>
                        编号
                    </template>
                    <el-tag size="small">{{ item.cabinetId }}</el-tag>
                </el-descriptions-item>
                <el-descriptions-item>
                    <template slot="label">
                        <i class="el-icon-location-outline"></i>
                        物品柜所在地
                    </template>
                    南京工业大学安全科学与工程学院
                </el-descriptions-item>
            </el-descriptions>
        </el-card>
        <el-card class="cabinet-card">
            <el-pagination
                @current-change="changeCurrentPage"
                @size-change="changeSizePage"
                background
                layout="total,sizes,prev, pager, next,jumper"
                :total="totalCabinet"
                :page-count="7"
                :page-sizes="[3,4,5,6]"
                :page-size="3"
                small
            >
            </el-pagination>
        </el-card>

        <el-dialog
            title="增加物品柜"
            :visible.sync="addCabinetDialogVisible"
            width="50%"
        >
            <br />
            <el-form
                ref="updateCabinetRef"
                :model="addCabinetForm"
                label-width="100px"
                :rules="updateCabinetRules"
            >
                <el-form-item label="物品柜名" prop="name">
                    <el-input v-model="addCabinetForm.name"></el-input>
                </el-form-item>
                <el-form-item label="物品柜编号" prop="cabinetId">
                    <el-input v-model="addCabinetForm.cabinetId"></el-input>
                </el-form-item>
                <el-form-item label="分类">
                    <el-select
                        width="100%"
                        v-model="valueType"
                        filterable
                        placeholder="请选择或输入物品柜分类"
                    >
                        <el-option
                            v-for="item in cabinetTypeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                            <span style="float: left">{{ item.label }}</span>
                            <span
                                style="
                                    float: right;
                                    color: #8492a6;
                                    font-size: 13px;
                                "
                                >{{ item.value }}</span
                            >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addCabinetDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="addCabinetRequest()"
                    >确 定</el-button
                >
            </span>
        </el-dialog>

        <el-dialog
            title="更新物品柜信息"
            :visible.sync="updateCabinetDialogVisible"
            width="50%"
        >
            <br />
            <el-form
                ref="updateCabinetRef"
                :model="updateCabinetForm"
                label-width="100px"
                :rules="updateCabinetRules"
            >
                <el-form-item label="物品柜名" prop="name">
                    <el-input v-model="updateCabinetForm.name"></el-input>
                </el-form-item>
                <el-form-item label="物品柜编号" prop="cabinetId">
                    <el-input v-model="updateCabinetForm.cabinetId"></el-input>
                </el-form-item>
                <el-form-item label="更改负责人">
                    <el-select
                        v-model="valueAdmin"
                        filterable
                        placeholder="请选择或输入姓名"
                    >
                        <el-option
                            v-for="item in adminList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                            <span style="float: left">{{ item.label }}</span>
                            <span
                                style="
                                    float: right;
                                    color: #8492a6;
                                    font-size: 13px;
                                "
                                >{{ item.name }}</span
                            >
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="更改分类">
                    <el-select
                        v-model="valueType"
                        filterable
                        placeholder="请选择或输入物品柜分类"
                    >
                        <el-option
                            v-for="item in cabinetTypeList"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                            <span style="float: left">{{ item.label }}</span>
                            <span
                                style="
                                    float: right;
                                    color: #8492a6;
                                    font-size: 13px;
                                "
                                >{{ item.value }}</span
                            >
                        </el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="updateCabinetDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="updateCabinetRequest()"
                    >确 定</el-button
                >
            </span>
        </el-dialog>

        <el-dialog
            title="物品柜分类"
            :visible.sync="cabinetTypeDialogVisible"
            width="50%"
        >
            <el-tabs type="border-card">
                <el-tab-pane
                    v-for="item in typeList"
                    :key="item.id"
                    :label="item.name"
                    >对于该分类的描述</el-tab-pane
                >
            </el-tabs>
            <span slot="footer" class="dialog-footer">
                <el-button
                    type="warning"
                    @click="addCabinetTypeDialogVisible = true"
                    >增加分类</el-button
                >
                <el-button
                    type="primary"
                    @click="cabinetTypeDialogVisible = false"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <el-dialog
            title="增加分类"
            :visible.sync="addCabinetTypeDialogVisible"
            width="50%"
        >
            <el-form
                ref="updateCabinetRef"
                :model="addCabinetTypeForm"
                label-width="100px"
                :rules="updateCabinetRules"
            >
                <el-form-item label="分类名" prop="name">
                    <el-input v-model="addCabinetTypeForm.name"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addCabinetTypeDialogVisible = false"
                    >取 消</el-button
                >
                <el-button type="primary" @click="addCabinetType()"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            totalPage: null, //总页数
            totalCabinet: null, //物品柜总数
            addCabinetTypeForm: {
                name: "",
            }, //新增物品柜分类
            typeList: [], //物品柜分类列表
            ////////////////////////
            adminList: [],
            valueAdmin: "",
            //以上为选择负责人////////
            cabinetTypeList: [],
            valueType: "",
            //以上为更改物品柜信息/////
            cabinetList: [], //修饰过后的物品柜列表
            cabinetListBefore: [], //物品柜列表初始值
            searchCabinet: "", //搜索物品柜
            page: {
                pageNum: 1,
                pageSize: 3,
            },
            addCabinetForm: {
                name: "",
                cabinetId: "",
                typeId: "",
            },
            addCabinetTypeDialogVisible: false,
            addCabinetDialogVisible: false, //增加物品柜打开对话框
            cabinetTypeDialogVisible: false,
            updateCabinetDialogVisible: false,
            updateCabinetForm: {
                name: "",
                cabinetId: "",
                typeId: "",
                ownerId: "",
            },
            //添加表单校验规则
            updateCabinetRules: {
                name: [
                    {
                        required: true,
                        message: "请输入物品柜名",
                        trigger: "blur",
                    },
                    {
                        min: 3,
                        max: 10,
                        message: "物品柜名长度在3-10个字符之间",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        this.getCabinets();
    },
    methods: {
        async getCabinets() {
            const { data: res } = await this.$http.get(
                "cabinet/listall",
                {
                    params: {
                        pageNum: this.page.pageNum,
                        pageSize: this.page.pageSize,
                    },
                },
                { emulateJSON: true }
            );
            //console.log(res);
            this.cabinetListBefore = res.data.records;
            this.totalCabinet = res.data.total;
            this.totalPage = (this.totalCabinet / 3) * 10;
            this.getLeaderInfo();
        },
        async getLeaderInfo() {
            //获取初始值列表中的负责人信息
            for (let i = 0; i < this.cabinetListBefore.length; i++) {
                let url = "user/info/id/" + this.cabinetListBefore[i].ownerId;
                const { data: res } = await this.$http.get(url);
                if (!res.data) {
                    var leaderNameById = "--";
                    var leaderPhoneNumberById = "--";
                    var leaderEmailById = "--";
                } else {
                    var leaderNameById = res.data.name;
                    var leaderPhoneNumberById = res.data.phoneNumber;
                    var leaderEmailById = res.data.email;
                }
                let urlType =
                    "cabinet/type/" + this.cabinetListBefore[i].typeId;
                const { data: resType } = await this.$http.get(urlType);
                //console.log(resType)
                if (!resType.data) {
                    var typeById = "--";
                } else {
                    var typeById = resType.data;
                }
                //console.log(resType)

                let cabinet = {
                    leaderId: this.cabinetListBefore[i].ownerId,
                    cabinetId: this.cabinetListBefore[i].cabinetId,
                    id: this.cabinetListBefore[i].id,
                    cabinetTypeId: this.cabinetListBefore[i].typeId,
                    cabinetName: this.cabinetListBefore[i].name,
                    cabinetType: typeById,
                    leaderName: leaderNameById,
                    leaderPhoneNumber: leaderPhoneNumberById,
                    leaderEmail: leaderEmailById,
                };
                //console.log(cabinet)
                this.cabinetList.push(cabinet);
            }
        },
        changeCurrentPage(page) {
            this.page.pageNum = page;
            console.log(this.page);
            this.cabinetList = [];
            this.cabinetListBefore = [];
            this.getCabinets();
        },
        changeSizePage(page) {
            this.page.pageSize = page;
            console.log(this.page);
            this.cabinetList = [];
            this.cabinetListBefore = [];
            this.getCabinets();
        },
        //查看物品柜内物品
        async openCabinetList(id) {
            let url = "cabinet/getcontent";
            const { data: res } = await this.$http.get(
                url,
                {
                    params: {
                        id: id,
                        pageNum: 1,
                        pageSize:5,
                    },
                },
                { emulateJSON: true }
            );
            console.log(res)
        },
        //删除物品柜
        deleteCabinet(id) {
            this.$confirm("此操作将删除物品柜，请谨慎操作。", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.deleteCabinetRequest(id);
                })
                .catch(() => {});
        },
        async deleteCabinetRequest(id) {
            let url = "cabinet/remove/" + id;
            const { data: res } = await this.$http.get(url);
            if (res.code) {
                this.$alert("删除成功！", "成功", {
                    confirmButtonText: "确定",
                    type: "success",
                    callback: (action) => {
                        location.reload();
                        this.$router.go(0);
                    },
                });
            } else {
                this.$alert("删除失败", "错误", {
                    confirmButtonText: "确定",
                    type: "warning",
                    callback: (action) => {
                        location.reload();
                        this.$router.go(0);
                    },
                });
            }
        },
        async addCabinet() {
            this.addCabinetDialogVisible = true;
            const { data: resType } = await this.$http.get(
                "cabinet/type/listall"
            );
            let type = resType.data;
            this.cabinetTypeList = [];
            for (let i = 0; i < type.length; i++) {
                let typeObject = {
                    label: type[i].name,
                    value: type[i].id,
                };
                this.cabinetTypeList.push(typeObject);
            }
        },
        async addCabinetRequest() {
            this.addCabinetForm.typeId = this.valueType;
            console.log(this.addCabinetForm);
            const { data: res } = await this.$http.get(
                "cabinet/add",
                {
                    params: {
                        cabinetId: this.addCabinetForm.cabinetId,
                        name: this.addCabinetForm.name,
                        typeId: this.addCabinetForm.typeId,
                    },
                },
                { emulateJSON: true }
            );
            if (!res.code) {
                this.$alert(res.message, "错误", {
                    confirmButtonText: "确定",
                    type: "warning",
                    callback: (action) => {
                        location.reload();
                        this.$router.go(0);
                    },
                });
            } else {
                this.$alert("添加物品柜成功！", "成功", {
                    confirmButtonText: "确定",
                    type: "success",
                    callback: (action) => {
                        location.reload();
                        this.$router.go(0);
                    },
                });
            }
        },
        async addCabinetType() {
            console.log(this.addCabinetTypeForm.name);
            const { data: res } = await this.$http.get(
                "cabinet/type/add",
                { params: { name: this.addCabinetTypeForm.name } },
                { emulateJSON: true }
            );
            if (!res.code) {
                this.$alert(res.message, "错误", {
                    confirmButtonText: "确定",
                    type: "warning",
                    callback: (action) => {
                        location.reload();
                        this.$router.go(0);
                    },
                });
            } else {
                this.addCabinetTypeDialogVisible = false;
                this.cabinetTypeDialogVisible = false;
                this.$notify({
                    title: "成功",
                    message: "添加物品柜分类成功！",
                    type: "success",
                });
            }
        },
        //更新物品柜信息
        async updateCabinet(item) {
            this.valueType = item.cabinetType;
            this.updateCabinetForm.typeId = item.cabinetTypeId;
            sessionStorage.setItem("id-cabinet", item.id);
            this.updateCabinetDialogVisible = !this.updateCabinetDialogVisible;
            this.updateCabinetForm.name = item.cabinetName;
            this.updateCabinetForm.ownerId = item.leaderId;
            this.updateCabinetForm.cabinetId = item.cabinetId;
            if (item.leaderName == "--") {
                this.valueAdmin = null;
            } else {
                this.valueAdmin = item.leaderName;
            }
            const { data: resType } = await this.$http.get(
                "cabinet/type/listall"
            );
            let type = resType.data;
            this.cabinetTypeList = [];
            for (let i = 0; i < type.length; i++) {
                let typeObject = {
                    label: type[i].name,
                    value: type[i].id,
                };
                this.cabinetTypeList.push(typeObject);
            }

            const { data: res } = await this.$http.get("user/listadmin");
            this.adminList = [];
            if (!res.data.length) {
                this.$alert("获取管理员列表失败", "错误", {
                    confirmButtonText: "确定",
                    type: "warning",
                    callback: (action) => {
                        //location.reload();
                        //this.$router.go(0);
                    },
                });
            } else {
                for (let i = 0; i < res.data.length; i++) {
                    let adminObj = {
                        label: res.data[i].username,
                        name: res.data[i].name,
                        value: res.data[i].id,
                    };
                    this.adminList.push(adminObj);
                }
                console.log(this.adminList);
            }
        },
        async updateCabinetRequest() {
            //校验是否为汉子/数字
            var chinese = new RegExp("[\u4E00-\u9FA5]+");
            var number = new RegExp("[0-9]+");
            if (!this.valueType) {
            } else if (chinese.test(this.valueType)) {
            } else if (number.test(this.valueType)) {
                this.updateCabinetForm.typeId = this.valueType;
            }
            if (!this.valueAdmin) {
            } else if (chinese.test(this.valueAdmin)) {
            } else if (number.test(this.valueAdmin)) {
                this.updateCabinetForm.ownerId = this.valueAdmin;
            }
            let id = sessionStorage.getItem("id-cabinet");
            let url = "cabinet/update/" + id;
            const { data: res } = await this.$http.post(
                url,
                this.updateCabinetForm
            );
            console.log(this.updateCabinetForm);
            if (!res.code) {
                this.$alert(res.message, "错误", {
                    confirmButtonText: "确定",
                    type: "warning",
                    callback: (action) => {
                        location.reload();
                        this.$router.go(0);
                    },
                });
            } else {
                this.$alert("更新物品柜信息成功", "成功", {
                    confirmButtonText: "确定",
                    type: "success",
                    callback: (action) => {
                        location.reload();
                        this.$router.go(0);
                    },
                });
            }
        },
        //所有物品柜分类
        async cabinetTypeAll() {
            this.cabinetTypeDialogVisible = !this.cabinetTypeDialogVisible;
            const { data: res } = await this.$http.get("cabinet/type/listall");
            this.typeList = res.data;
        },
    },
};
</script>
<style lang="less" scoped>
.font {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    .box-card {
        width: 100%;
        margin-top: 8px;
        border-radius: 10px;
    }
    .el-card__body,
    .el-main {
        height: 100%;
    }
    .index {
        font-size: 230px;
        font-weight: 800;
    }
    .optionSelectValue {
        float: right;
        color: #8492a6;
        font-size: 13px;
    }
    .total-number {
        //float: right;
        margin-left: 20px;
    }
    .cabinet-card{
        width: 100%;
        margin-top: 8px;
        border-radius: 10px;
        //padding: 5px;
    }
    
    .el-pagination.is-background .el-pager li:hover {
        color: #389a99;
    }
    .el-pagination.is-background .el-pager li:not(.disabled) :hover {
        color: #389a99;
    }
    .el-pagination.is-background .el-pager li:not(.disabled) .active:hover {
        background-color: #389a99;
    }
    .el-pagination.is-background .el-pager li:not(.disabled) .active {
        background-color: #389a99;
    }
}
</style>
