<template>
    <div class="font">
        <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="breadcrumb-move"
        >
            <el-breadcrumb-item :to="{ path: '/welcome' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>welcome</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card">
            <el-row :gutter="12">
                <el-col :span="6">
                    <div class="wel-border first">
                        <div class="avatar" style="background-color: #f56c6c">
                            <i class="el-icon-circle-close icon"></i>
                        </div>
                        <div class="num">
                            <div class="title-num">危险</div>
                            <div class="desc-num">123</div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="wel-border second">
                        <div class="avatar" style="background-color: #e6a23c">
                            <i class="el-icon-bell icon"></i>
                        </div>
                        <div class="num">
                            <div class="title-num">待处理</div>
                            <div class="desc-num">25</div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="wel-border third">
                        <div class="avatar" style="background-color: #67c23a">
                            <i class="el-icon-circle-check icon"></i>
                        </div>
                        <div class="num">
                            <div class="title-num">成功</div>
                            <div class="desc-num">149</div>
                        </div>
                    </div>
                </el-col>
                <el-col :span="6">
                    <div class="wel-border fourth">
                        <div class="avatar" style="background-color: #409eff">
                            <i class="el-icon-user icon"></i>
                        </div>
                        <div class="num">
                            <div class="title-num">在线人数</div>
                            <div class="desc-num">200</div>
                        </div>
                    </div>
                </el-col>
            </el-row>
            <el-row :gutter="20">
                <el-col :span="14">
                    <el-card
                        shadow="hover"
                        style="width: 100%"
                        class="box-card"
                        >
	<div id="main" style="width: 100%;height: 450px;background:#fff"></div>
</el-card
                    >
                </el-col>
                <el-col :span="10">
                    <el-card
                        shadow="hover"
                        style="width: 100%"
                        class="box-card"
                    >
                        公告
                        <el-collapse v-model="activeName" accordion>
                            <el-collapse-item
                                title="系统更新 2023-05-01"
                                name="1"
                            >
                                <ul>
                                    <li>完善系统权限管理逻辑</li>
                                    <li>完成药品借用</li>
                                </ul>
                            </el-collapse-item>
                            <el-collapse-item
                                title="系统更新 2023-04-23"
                                name="2"
                            >
                                <ul>
                                    <li>后端框架搭建完成</li>
                                    <li>前端完成物品柜部分</li>
                                    <li>完成router路由管理</li>
                                </ul>
                            </el-collapse-item>
                            <el-collapse-item
                                title="系统更新 2023-04-11"
                                name="3"
                            >
                                <ul>
                                    <li>完善首页部分</li>
                                    <li>数据库设计完成，并逐步完善开发文档</li>
                                </ul>
                            </el-collapse-item>
                            <el-collapse-item
                                title="系统更新 2023-04-02（重构）important"
                                name="4"
                            >
                                <ul>
                                    <li>后端进行重构，采用go+gin技术栈</li>
                                    <li>前端完成用户逻辑</li>
                                </ul>
                            </el-collapse-item>
                            <el-collapse-item
                                title="系统更新 2023-02-28（重构）important"
                                name="5"
                            >
                                <ul>
                                    <li>
                                        前端进行重构，采用vue3+element design
                                    </li>
                                    <li>
                                        由于甲方需求变更，旧版系统停止维护更新
                                    </li>
                                </ul>
                            </el-collapse-item>
                            <el-collapse-item
                                title="系统更新 2023-01-31"
                                name="6"
                            >
                                <ul>
                                    <li>
                                        商议需求，暂停开发
                                    </li>
                                    
                                </ul>
                            </el-collapse-item>
                        </el-collapse>
                    </el-card>
                </el-col>
            </el-row>
        </el-card>
    </div>
</template>
<script>
import * as echarts from "echarts";
export default {
    data() {
        return {
            deadline2: Date.now() + 1000 * 60 * 60 * 8,
            deadline3: Date.now() + 1000 * 60 * 30,
            deadline4:
                Date.now() + (new Date().setHours(23, 59, 59) - Date.now()),
            stop: true,
            activeName: "1",
            charts: '',
				opinionData: ["155", "400", "900", "800", "300", "900", "270"]//数据
        };
    },
    created() {},
    mounted(){
        this.render()
    },
    methods: {
        clickFn() {
            this.$refs.statistic.suspend(this.stop);
            this.stop = !this.stop;
        },
        drawLine(id) {
				this.charts = echarts.init(document.getElementById(id))
				this.charts.setOption({
                    title:{
                        left:'3%',
                        top:'5%',
                        text:"最近一周药品进出数量",//标题文本，支持使用 \n 换行。
                    },
					tooltip: {
						trigger: 'axis'
					},
					legend: {
                        align:'right',//文字在前图标在后
                        left:'3%',
                        top:'15%',
						data: ['近一周']
					},
					grid: {
                        top:'30%',
						left: '5%',
						right: '5%',
						bottom: '5%',
						containLabel: true
					},
 
					toolbox: {
						feature: {
							saveAsImage: {} //保存为图片
						}
					},
					xAxis: {
						type: 'category',
                        boundaryGap:true,
                        axisTick:{
                            alignWithLabel:true //保证刻度线和标签对齐
                        },
                        data: ["周一","周二","周三","周四","周五","周六","周日"] //x坐标的名称
					
					},
					yAxis: {
						type: 'value',
						boundaryGap: true,
                        splitNumber:4, //纵坐标数
                        interval:250 //强制设置坐标轴分割间隔
					},
 
					series: [{
						name: '近一周',
						type: 'line', //折线图line;柱形图bar;饼图pie
						stack: '总量',
                        areaStyle: {
                            //显示区域颜色---渐变效果
                            color:{
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 0,
                                y2: 1,
                                colorStops: [{
                                    offset: 0, color: 'rgb(255,200,213)' // 0% 处的颜色
                                }, {
                                    offset: 1, color: '#ffffff' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }
                        },
                        itemStyle: {
							color: 'rgb(255,96,64)', //改变折线点的颜色
							lineStyle: {
								color: 'rgb(255,96,64)' //改变折线颜色
							}
                            
                        },
						data: this.opinionData
					}]
				})
			}

    },
    mounted() {
			this.$nextTick(function() {
				this.drawLine('main')
			})
		}

};
</script>
<style lang="less" scoped>
.font {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    .wel-border {
        border-radius: 10px;
        border: 2px solid #ebeef5;
        height: 60px;
        transition: box-shadow 0.5s;
        padding: 5px;
    }
    .wel-border:hover {
        box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
    }
    .avatar {
        height: 100%;
        border-radius: 5px;
        width: 60px;
        float: left;
    }
    .icon {
        font-size: 36px;
        color: #fff;
        //margin: auto;
        align-items: center;
        justify-content: center;
        display: flex;
        transform: translate(0, 35%);
    }
    .num {
        transform: translate(15%);
    }
    .title-num {
        color: #909399;
        font-size: 13px;
    }
    .desc-num {
        color: #303133;
        font-size: 36px;
    }
    .first {
        transition: border 0.5s;
    }
    .second {
        transition: border 0.5s;
    }
    .third {
        transition: border 0.5s;
    }
    .fourth {
        transition: border 0.5s;
    }
    .first:hover {
        border: 2px solid #f56c6c;
    }
    .second:hover {
        border: 2px solid #e6a23c;
    }
    .third:hover {
        border: 2px solid #67c23a;
    }
    .fourth:hover {
        border: 2px solid #409eff;
    }
    .box-card {
        width: 100%;
        margin-top: 13px;
        border-radius: 8px;
    }
    .el-card__body,
    .el-main {
        height: 100%;
    }
    .index {
        font-size: 20px;
        font-weight: 800;
    }
}
</style>
