<template>
    <div class="font">
        <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="breadcrumb-move"
        >
            <el-breadcrumb-item :to="{ path: '/welcome' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>物视镜</el-breadcrumb-item>
            <el-breadcrumb-item>物品借用</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card">
            <el-steps :active="1" simple>
                <el-step title="提交申请"></el-step>
                <el-step title="第二申请" description="危化品需要两人同时申请"></el-step>
                <el-step
                    title="审核一"
                    description="危化品需要两位管理员进行审核"
                ></el-step>
                <el-step
                    title="审核二"
                ></el-step>
                <el-step title="成功" description=""></el-step>
            </el-steps>
        </el-card>
        <el-card class="box-card">
            <el-row :gutter="20">
                <el-col :span="5">
                    <el-tag>药品借用表单</el-tag>
                </el-col>
                <el-col :span="14"
                    ><div class="grid-content bg-purple">
                        <div>
                            <el-form
                                ref="form"
                                :model="form"
                                label-width="80px"
                            >
                                <el-form-item label="实验名称">
                                    <el-input v-model="form.name"></el-input>
                                </el-form-item>
                                <el-form-item label="药品名称">
                                    <el-select
                                        v-model="form.region"
                                        placeholder="请选择药品"
                                    >
                                        <el-option
                                            label="乙醇"
                                            value="shanghai"
                                        ></el-option>
                                        <el-option
                                            label="乌洛托品"
                                            value="beijing"
                                        ></el-option>
                                        <el-option
                                            label="甲烷"
                                            value="shanghai"
                                        ></el-option>
                                        <el-option
                                            label="乙醚"
                                            value="beijing"
                                        ></el-option>
                                        <el-option
                                            label="苯酚"
                                            value="shanghai"
                                        ></el-option>
                                        <el-option
                                            label="氨基酸"
                                            value="beijing"
                                        ></el-option>
                                    </el-select>
                                </el-form-item>
                                <el-form-item label="归还时间">
                                    <el-col :span="11">
                                        <el-date-picker
                                            type="date"
                                            placeholder="选择日期"
                                            v-model="form.date1"
                                            style="width: 100%"
                                        ></el-date-picker>
                                    </el-col>
                                    <el-col class="line" :span="2">
                                        <div align="center">——</div>
                                    </el-col>
                                    <el-col :span="11">
                                        <el-time-picker
                                            placeholder="选择时间"
                                            v-model="form.date2"
                                            style="width: 100%"
                                        ></el-time-picker>
                                    </el-col>
                                </el-form-item>
                                <el-form-item label="立即取用">
                                    <el-switch
                                        v-model="form.delivery"
                                    ></el-switch>
                                </el-form-item>

                                <el-form-item label="安全选项">
                                    <el-radio-group v-model="form.resource">
                                        <el-radio label="高危险实验"></el-radio>
                                        <el-radio
                                            label="一般危险实验"
                                        ></el-radio>
                                        <el-radio label="简单实验"></el-radio>
                                    </el-radio-group>
                                </el-form-item>
                                <el-form-item label="备注">
                                    <el-input
                                        type="textarea"
                                        v-model="form.desc"
                                    ></el-input>
                                </el-form-item>
                                <el-form-item>
                                    <el-button type="primary" @click="onSubmit"
                                        >立即创建</el-button
                                    >
                                    <el-button>取消</el-button>
                                </el-form-item>
                            </el-form>
                        </div>
                    </div></el-col
                >
                <el-col :span="5"
                    ><div class="grid-content bg-purple"></div
                ></el-col>
            </el-row>
        </el-card>
    </div>
</template>
<script>
export default {
    data() {
        return {
            form: {
                name: "",
                region: "",
                date1: "",
                date2: "",
                delivery: false,
                type: [],
                resource: "",
                desc: "",
            },
        };
    },
    created() {},
    methods: {},
};
</script>
<style lang="less" scoped>
.font {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    .box-card {
        width: 100%;
        margin-top: 13px;
        border-radius: 8px;
    }
    .el-card__body,
    .el-main {
        height: 100%;
    }
    .index {
        font-size: 230px;
        font-weight: 800;
    }
}
</style>
