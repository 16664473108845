<template>
    <div class="font">
        <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="breadcrumb-move"
        >
            <el-breadcrumb-item :to="{ path: '/welcome' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>团队成员管理</el-breadcrumb-item>
            <el-breadcrumb-item>团队清单</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card">
            <!--搜索、添加用户-->
            <el-row :gutter="20">
                <el-col :span="8">
                    <el-input
                        placeholder="请输入搜索内容"
                        class="input-with-select"
                        v-model="adminSearchQuery"
                    >
                        <el-button
                            slot="append"
                            icon="el-icon-search"
                            @click="searchByUsername()"
                        ></el-button>
                    </el-input>
                </el-col>
                <el-col :span="4">
                    <el-button type="primary" @click="addDialogVisible = true"
                        >添加团队</el-button
                    >
                </el-col>
            </el-row>
            <!--渲染列表-->
            <el-table
                :data="teamListAll"
                border
                stripe
                :max-height="screenHeight"
            >
                <el-table-column type="index" label="#"></el-table-column>
                <el-table-column
                    label="团队名称"
                    prop="teamName"
                    width="120px"
                ></el-table-column>
                <el-table-column
                    label="创建时间"
                    prop="createTeamTime"
                    width="170px"
                ></el-table-column>
                <el-table-column label="负责人" prop="leaderName" width="80px">
                </el-table-column>
                <el-table-column
                    label="联系方式"
                    prop="leaderPhoneNumber"
                    width="130px"
                ></el-table-column>
                <el-table-column
                    label="邮箱"
                    prop="leaderEmail"
                    width="190px"
                ></el-table-column>
                <el-table-column label="团队人数" width="90px" prop="leaderId">
                </el-table-column>
                <el-table-column label="操作" width="190px">
                    <template slot-scope="scope">
                        <el-tooltip
                            effect="dark"
                            content="查看团队成员信息"
                            placement="top"
                            :enterable="false"
                        >
                            <el-button
                                type="primary"
                                icon="el-icon-s-promotion"
                                size="mini"
                                @click="openTeamInfo()"
                            ></el-button>
                        </el-tooltip>
                        <el-tooltip
                            effect="dark"
                            content="设置负责人"
                            placement="top"
                            :enterable="false"
                        >
                            <el-button
                                type="warning"
                                icon="el-icon-circle-plus-outline"
                                size="mini"
                                @click="addUserDialogVisible = true"
                            ></el-button>
                        </el-tooltip>
                        <el-tooltip
                            effect="dark"
                            content="删除团队"
                            placement="top"
                            :enterable="false"
                            ><el-button
                                type="danger"
                                icon="el-icon-delete"
                                size="mini"
                                @click="deleteTeam(scope.row)"
                            ></el-button
                        ></el-tooltip>
                    </template>
                </el-table-column>
            </el-table>
        </el-card>

        <!--添加用户对话框-->
        <el-dialog
            title="添加团队"
            :visible.sync="addDialogVisible"
            width="50%"
        >
            <el-form
                ref="addTeamFormRef"
                :model="addTeamForm"
                :rules="addTeamFormRules"
                label-width="80px"
            >
                <el-form-item label="团队名称" prop="name">
                    <el-input v-model="addTeamForm.name"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addTeam()">确 定</el-button>
            </span>
        </el-dialog>
        <el-dialog
            title="设置团队负责人"
            :visible.sync="addUserDialogVisible"
            width="50%"
        >
            <el-form
                ref="addUserRef"
                :model="addUserForm"
                label-width="80px"
                :rules="addUserFormRules"
            >
                <el-form-item label="用户名" prop="name">
                    <el-select
                        v-model="value"
                        multiple
                        filterable
                        remote
                        reserve-keyword
                        placeholder="请输入用户名"
                        :remote-method="remoteMethod"
                        :loading="loading"
                    >
                        <el-option
                            v-for="item in options"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                        >
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="姓名" prop="name">
                    <el-input v-model="addUserForm.name"></el-input>
                </el-form-item>
                <el-form-item label="邮箱" prop="email">
                    <el-input v-model="addUserForm.email"></el-input>
                </el-form-item>
                <el-form-item label="联系方式" prop="phoneNumber">
                    <el-input v-model="addUserForm.phoneNumber"></el-input>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addUserInfoRequest()"
                    >确 定</el-button
                >
            </span>
        </el-dialog>
        <!-- 查看团队信息 -->
        <el-dialog
            title="团队信息"
            :visible.sync="teamInfoDialogVisible"
            width="50%"
        >
        <el-button type="primary" round icon="el-icon-circle-plus-outline">添加成员</el-button>
            <el-table :data="teamInfo" border stripe :max-height="screenHeight">
                <el-table-column type="index" label="#"></el-table-column>
                <el-table-column
                    prop="date"
                    label="用户名"
                ></el-table-column>
                <el-table-column
                    prop="address"
                    label="身份"
                ></el-table-column>
                <el-table-column
                    prop="name"
                    label="姓名"
                    
                ></el-table-column>
                <el-table-column
                    prop="address"
                    label="联系方式"
                ></el-table-column>
                <el-table-column
                    prop="address"
                    label="邮箱"
                ></el-table-column>
            </el-table>
        </el-dialog>
    </div>
</template>
<script>
export default {
    data() {
        return {
            //团队增加用户动态搜索区域
            options: [],
            value: [],
            list: [],
            loading: false,
            states: [],
            //团队增加用户动态搜索区域

            //以上不可改动
            teamList: [], //初步团队信息数组
            teamListAll: [], //根据初步获得的团队信息进行数据修饰，最终渲染进表格的数据

            teamInfo: [], //团队所有成员信息
            updateRowId: "", //超级管理员更新信息时的用户id
            screenHeight: sessionStorage.getItem("screen-height") - 280, //表格绑定最高高度
            adminSearchQuery: "", //搜索用户绑定值
            addDialogVisible: false, //添加团队可见绑定值
            addUserDialogVisible: false, //更新团队信息可见绑定值
            teamInfoDialogVisible: false, //查看团队信息可见绑定值
            addUserForm: {
                name: "",
                email: "",
                phoneNumber: "",
            },
            addUserFormRules: {
                name: [
                    {
                        required: true,
                        message: "请输入用户名",
                        trigger: "blur",
                    },
                ],
                email: [
                    {
                        validator: this.validateEmail,
                        trigger: "blur",
                    },
                ],
                phoneNumber: [
                    {
                        validator: this.validateMobilePhone,
                        trigger: "blur",
                    },
                ],
            },
            //添加用户表单
            addTeamForm: {
                name: "",
            },
            //添加表单校验规则
            addTeamFormRules: {
                name: [
                    {
                        required: true,
                        message: "请输入团队名称",
                        trigger: "blur",
                    },
                    {
                        min: 3,
                        max: 16,
                        message: "用户名长度在3-16个字符之间",
                        trigger: "blur",
                    },
                ],
            },
        };
    },
    created() {
        //this.addLeaderInfo();
    },
    mounted() {
        this.getTeamList();
        //团队增加用户动态搜索区域
        this.list = this.states.map((item) => {
            return { value: `value:${item}`, label: `label:${item}` };
        });
        //团队增加用户动态搜索区域
    },
    methods: {
        //团队增加用户动态搜索区域
        remoteMethod(query) {
            if (query !== "") {
                this.loading = true;
                let url = "user/search/username/" + query;
                this.$http.get(url).then((response) => {
                    this.states = response.data["content"];
                });
                setTimeout(() => {
                    this.loading = false;
                    this.options = this.list.filter((item) => {
                        return (
                            item.label
                                .toLowerCase()
                                .indexOf(query.toLowerCase()) > -1
                        );
                    });
                }, 1000);
            } else {
                this.options = [];
            }
        },
        //团队增加用户动态搜索区域
        //获取管理员列表
        async getTeamList() {
            const { data: res } = await this.$http.get("team/listAll");
            if (!res.code) {
                this.$alert(res.message, "错误", {
                    confirmButtonText: "确定",
                    type: "danger",
                    callback: (action) => {},
                });
            }
            this.teamList = res.data;
            this.addLeaderInfo();
        },
        //获取团队的负责人信息并渲染进表格
        async addLeaderInfo() {
            for (let i = 0; i < this.teamList.length; i++) {
                if (!this.teamList[i].leaderId) {
                    let addTeamList = {
                        leaderName: "--",
                        leaderEmail: "--",
                        leaderPhoneNumber: "--",
                        teamName: this.teamList[i].name,
                        createTeamTime: this.teamList[i].createTime,
                    };
                    //Object.assign(this.teamList[i], addTeamList);
                    this.teamListAll.push(addTeamList);
                } else {
                    let url = "user/info/id/" + this.teamList[i].leaderId;
                    const { data: res } = await this.$http.get(url);
                    let leaderInfo = res.data;
                    let addTeamList = {
                        leaderName: leaderInfo.name,
                        leaderEmail: leaderInfo.email,
                        leaderPhoneNumber: leaderInfo.phoneNumber,
                        teamName: this.teamList[i].name,
                        createTeamTime: this.teamList[i].createTime,
                    };
                    //Object.assign(this.teamList[i], addTeamList);
                    this.teamListAll.push(addTeamList);
                    console.log(this.teamListAll);
                }
            }
        },
        //搜索用户
        async searchByUsername() {
            let url = "user/search/username/" + this.adminSearchQuery;
            const { data: res } = await this.$http.get(url);
            this.adminList = res.data;
            if (res.data == null) {
                this.$alert("该用户不存在！", "警告", {
                    confirmButtonText: "确定",
                    type: "warning",
                    callback: (action) => {
                        location.reload();
                        this.$router.go(0);
                    },
                });
            }
        },
        addTeam() {
            this.$refs.addTeamFormRef.validate(async (valid) => {
                if (!valid) {
                    return;
                }
                let url = "team/add/" + this.addTeamForm.name;
                const { data: res } = await this.$http.get(url);
                if (!res.code) {
                    this.$alert(res.message, "警告", {
                        confirmButtonText: "确定",
                        type: "warning",
                        callback: (action) => {
                            location.reload();
                            this.$router.go(0);
                        },
                    });
                } else {
                    this.$alert("团队添加成功！", "成功", {
                        confirmButtonText: "确定",
                        type: "success",
                        callback: (action) => {
                            location.reload();
                            this.$router.go(0);
                        },
                    });
                }
            });
        },
        async deleteTeam(userInfo) {
            this.$confirm("是否确定删除该团队？", "警告", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            })
                .then(() => {
                    this.deleteTeamRequest(userInfo);
                })
                .catch(() => {
                    this.$notify.info({
                        title: "提示",
                        message: "取消操作成功",
                    });
                });
        },
        //删除团队请求
        async deleteTeamRequest(userInfo) {
            let url = "team/remove/" + userInfo.name;
            const { data: res } = await this.$http.get(url);
            if (res.code) {
                this.$alert("删除团队成功！", "成功", {
                    confirmButtonText: "确定",
                    type: "success",
                    callback: (action) => {
                        location.reload();
                        this.$router.go(0);
                    },
                });
            } else {
                this.$alert("删除团队失败！", "成功", {
                    confirmButtonText: "确定",
                    type: "warning",
                    callback: (action) => {
                        location.reload();
                        this.$router.go(0);
                    },
                });
            }
        },
        async addUserRequest() {
            let url = "user/update/" + this.updateRowId;
            const { data: res } = await this.$http.post(
                url,
                this.updateUserForm
            );
            if (res.code) {
                this.$alert("更新用户信息成功", "成功", {
                    confirmButtonText: "确定",
                    type: "success",
                    callback: (action) => {
                        location.reload();
                        this.$router.go(0);
                    },
                });
            } else {
                this.$alert(res.message, "错误", {
                    confirmButtonText: "确定",
                    type: "warning",
                    callback: (action) => {
                        location.reload();
                        this.$router.go(0);
                    },
                });
            }
        },
        openTeamInfo() {
            this.teamInfoDialogVisible = !this.teamInfoDialogVisible;
        },
        //更新用户信息的邮箱校验规则
        validateEmail(rule, value, callback) {
            if (value === "") {
                callback(new Error("请填写邮箱"));
            } else {
                if (value !== "") {
                    var reg =
                        /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
                    if (!reg.test(value)) {
                        callback(new Error("请输入正确的邮箱格式"));
                    }
                }
                callback();
            }
        },
        //更新用户信息的手机号校验规则
        validateMobilePhone(rule, value, callback) {
            if (value === "") {
                callback(new Error("手机号不可为空"));
            } else {
                if (value !== "") {
                    var reg = /^1[3456789]\d{9}$/;
                    if (!reg.test(value)) {
                        callback(new Error("请输入有效的手机号码"));
                    }
                }
                callback();
            }
        },
        //将原来表格内的roleId数字渲染为具体身份
        roleIdTurn(userInfo) {
            if (userInfo.roleId - 1) {
                return "普通成员";
            } else {
                return "管理员";
            }
        },
    },
};
</script>
<style lang="less" scoped>
.font {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    .box-card {
        width: 100%;
        margin-top: 13px;
        border-radius: 8px;
    }
    .el-card__body,
    .el-main {
        height: 100%;
    }
}
</style>
