<template>
    <div class="font">
        <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="breadcrumb-move"
        >
            <el-breadcrumb-item :to="{ path: '/welcome' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>个人中心</el-breadcrumb-item>
        </el-breadcrumb>
        <el-card class="box-card">
            <el-button type="primary" @click="logout()" round
            >退出系统
            </el-button
            >
          <el-button type="danger" @click="changePassword()" round
          >更改密码
          </el-button
          >
        </el-card>
        <el-card class="box-card">
            <el-row :gutter="20">
              <el-col :span="8"
              >
                <div class="grid-content bg-purple" id="headPhoto">
                  <el-avatar

                    :size="headPhotoSize"
                    :src="headPhotoUrl"
                    alt=""
                    class="headPhoto"
                    placeholder="正在加载"
                  ></el-avatar>
                </div
                >
              </el-col>
                <el-col :span="16"
                    ><div class="grid-content bg-purple">
                        <el-descriptions
                            class="margin-top"
                            title="个人信息"
                            :column="2"
                            border
                        >
                            <template slot="extra">
                                <el-button
                                    type="warning"
                                    size="small"
                                    round
                                    @click="updateDialogFormVisible = true"
                                    >更新信息</el-button
                                >
                            </template>
                            <el-descriptions-item label="用户名">{{
                                this.personalInformation.username
                            }}</el-descriptions-item>
                            <el-descriptions-item label="姓名">{{
                                this.personalInformation.name
                            }}</el-descriptions-item>
                            <el-descriptions-item label="团队">{{
                                this.personalInformation.team
                            }}</el-descriptions-item>
                            <el-descriptions-item label="邮箱">{{
                                this.personalInformation.email
                            }}</el-descriptions-item>
                            <el-descriptions-item label="联系方式">{{
                                this.personalInformation.phoneNumber
                            }}</el-descriptions-item>
                            <el-descriptions-item label="状态">{{
                                (this.personalInformation.isNotFrozen = 1
                                    ? "已激活"
                                    : "冻结")
                            }}</el-descriptions-item>
                            <el-descriptions-item label="身份">
                                <el-tag size="large">{{
                                    (this.personalInformation.roleId = 1
                                        ? "管理员"
                                        : "普通成员")
                                }}</el-tag>
                            </el-descriptions-item>
                        </el-descriptions>
                    </div></el-col
                >
            </el-row>
        </el-card>

        <el-dialog title="修改信息" :visible.sync="updateDialogFormVisible">
            <el-row :gutter="20">
                <el-col :span="8">
                    <div class="update-title">上传头像</div>
                    <el-upload
                        class="avatar-uploader"
                        action="https://xiangzhi.api.yueyuezi.com/file/upload"
                        :headers="header"
                        :show-file-list="false"
                        :on-success="uploadSuccess"
                        :before-upload="beforeAvatarUpload"
                    >
                        <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                        <i
                            v-else
                            class="el-icon-plus avatar-uploader-icon"
                        ></i> </el-upload
                ></el-col>

                <el-col :span="16">
                    <div class="update-title">个人数据</div>
                    <el-form :model="updateInfo">
                        <el-form-item label="姓名" label-width="80px">
                            <el-input
                                v-model="updateInfo.name"
                                autocomplete="off"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="邮箱" label-width="80px">
                            <el-input
                                v-model="updateInfo.email"
                                autocomplete="off"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="联系方式" label-width="80px">
                            <el-input
                                v-model="updateInfo.phoneNumber"
                                autocomplete="off"
                            ></el-input>
                        </el-form-item>
                        <el-form-item label="团队" label-width="80px">
                            <el-input
                                v-model="updateInfo.team"
                                autocomplete="off"
                            ></el-input>
                        </el-form-item> </el-form
                ></el-col>
            </el-row>

            <div slot="footer" class="dialog-footer">
                <el-button @click="updateDialogFormVisible = false"
                    >取 消</el-button
                >
              <el-button type="primary" @click="update()">更新信息</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
//import BScroll from 'better-scroll'
export default {
    data() {
        return {
          //头像
          headPhotoUrl: "",
          headPhotoSize: "",
          updateDialogFormVisible: false, //更新信息对话框是否可见
          updateInfo: {
            //用户更新信息绑定表单
            name: "",
            phoneNumber: "",
            team: "",
            email: "",
            avatar_url: "",
          },
          header: {
            //上传图片时加请求头
                satoken: "",
            },
            imageUrl: "",
            //用户个人信息获取
            personalInformation: {},
            passwordList: {
                oldPassword: "",
                newPassword: "",
            },
        };
    },
    created() {
        this.getUserInfo();
        this.header.satoken = sessionStorage.getItem("token");
    },
    methods: {
      async update() {
        const {data: res} = await this.$http.post(
          "/member/update",
          this.updateInfo
        );
        if (res.code) {
          console.log();
          this.$alert("修改信息成功！点击确定重新登录。", "成功", {
            confirmButtonText: "确定",
            type: "info",
            callback: (action) => {
              this.$router.push("/login");
            },
          });
        } else {
          this.$alert("更新失败", "错误", {
            confirmButtonText: "确定",
            type: "warning",
            callback: (action) => {
            },
          });
        }
      },
      logout() {
        this.$confirm("是否确定退出系统？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$http.get("/logout");
                    sessionStorage.clear();
                    //用户名，密码，token令牌均保存在session中
                    this.$router.push("/login");
                })
                .catch(() => {});
        },
        changePassword() {
            this.$prompt("请输入您的原始密码", "提示", {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                inputPattern: /^[0-9A-Za-z]{6,18}$/,
                inputErrorMessage: "密码格式不正确",
            })
                .then(({ value }) => {
                    let oldPassword = sessionStorage.getItem("password");
                    if (value == oldPassword) {
                        this.$prompt("请输入您的新密码", "提示", {
                            confirmButtonText: "确定",
                            cancelButtonText: "取消",
                            inputPattern:
                                /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,16}$/,
                            inputErrorMessage:
                                "新密码至少六位，至少一个字母和一个数字",
                        })
                            .then(({ value }) => {
                                //this.newPassword=value
                                this.newPassword(value);
                            })
                            .catch(() => {
                                this.$notify.info({
                                    title: "消息",
                                    message: "用户已取消更改密码。",
                                });
                            });
                    } else {
                        this.$alert(
                            "原始密码错误。若忘记密码请联系管理员重置。",
                            "错误",
                            {
                                confirmButtonText: "确定",
                                type: "warning",
                                callback: (action) => {
                                    this.changePassword();
                                },
                            }
                        );
                    }
                })
                .catch(() => {});
        },
        async getUserInfo() {
          let username = sessionStorage.getItem("username");
          let url = "/user/info/username/" + username;
          const {data: res} = await this.$http.get(url);
          this.personalInformation = res.data;
          this.updateInfo.name = this.personalInformation.name;
          this.updateInfo.email = this.personalInformation.email;
          this.updateInfo.team = this.personalInformation.team;
          this.updateInfo.phoneNumber = this.personalInformation.phoneNumber;

          //获取头像并下载
          let headPhoto = this.personalInformation.avatarUrl;

          let urlGetHead =
            "https://xiangzhi.api.yueyuezi.com/file/download/" + headPhoto;
          this.headPhotoUrl = urlGetHead;
          let headSize = document.getElementById("headPhoto");
          this.headPhotoSize = headSize.clientWidth;
          console.log(this.headPhotoSize);
          //const{data:resHead}=await this.$http.get(urlGetHead)
          //this.headPhoto=resHead
        },
        async newPassword(newPassword) {
            this.passwordList.oldPassword = sessionStorage.getItem("password");
            this.passwordList.newPassword = newPassword;
            const { data: res } = await this.$http.post(
                "/member/changepw",
                this.passwordList
            );
            if (res.code) {
                this.$alert("密码修改成功", "成功", {
                    confirmButtonText: "确定",
                    type: "success",
                    callback: (action) => {
                        this.$router.push("/login");
                    },
                });
            }
            if (!res.code) {
                this.$alert(res.message, "错误", {
                    confirmButtonText: "确定",
                    type: "warning",
                    callback: (action) => {
                        //this.$router.push("/login")
                    },
                });
            }
        },
        uploadSuccess(res, file) {
            if (res.code) {
                this.updateInfo.avatar_url = res.data;
            } else {
                this.message.error("头像上传失败！");
            }
            this.imageUrl = URL.createObjectURL(file.raw);
        },
        beforeAvatarUpload(file) {
            const isJPG = file.type === "image/jpeg";
            const isLt2M = file.size / 1024 / 1024 < 2;

            if (!isJPG) {
                this.$message.error("上传头像图片只能是 JPG 格式!");
            }
            if (!isLt2M) {
                this.$message.error("上传头像图片大小不能超过 2MB!");
            }

            return isJPG && isLt2M;
        },
    },
};
</script>
<style lang="less" >
.font {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    .box-card {
        width: 100%;
        margin-top: 13px;
        border-radius: 8px;
    }
    .el-card__body,
    .el-main {
        height: 100%;
    }
    .index {
        font-size: 230px;
        font-weight: 800;
    }
    .headPhoto {
      //width: 230px;
      //position: fixed;
      //transform: translate(470px, 30%);
      //border-radius: 50%;
        filter: drop-shadow(1px 2px 4px rgb(42, 42, 43));
        //box-shadow: 0 2px 4px rgba(0, 0, 0, 0.12), 0 0 6px rgba(0, 0, 0, 0.04);
    }
    .avatar-uploader .el-upload {
        border: 1px dashed #4a4848;
        border-radius: 50%;
        cursor: pointer;
        position: relative;
        overflow: hidden;
    }
    .avatar-uploader .el-upload:hover {
        //border-color: #409eff;
    }
    .avatar-uploader-icon {
        font-size: 28px;
        color: #434546;
        width: 200px;
        height: 200px;
        line-height: 200px;
        text-align: center;
        border: #409eff;
    }
    .avatar {
        width: 200px;
        height: 200px;
        display: block;
    }
    .update-title {
        font-weight: 600;
    }
}
</style>
