<template>
    <div class="font">
        <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="breadcrumb-move"
        >
            <el-breadcrumb-item :to="{ path: '/welcome' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>权限管理</el-breadcrumb-item>
            <el-breadcrumb-item>权限列表</el-breadcrumb-item>

        </el-breadcrumb>
        <el-card class="box-card">
            <img src="../../assets/roleList.png" alt="" style="width:100%;">
            
        </el-card>
 
        
    </div>
</template>
<script>
export default {
    data() {
        return {
            
        };
    },
    created() {

    },
    methods: {
    },
};
</script>
<style lang="less" scoped>
.font {
    font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB",
        "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
    .box-card {
        width: 100%;
        margin-top: 13px;
        border-radius: 8px;
    }
    .el-card__body,
    .el-main {
        height: 100%;
    }
    .index{
        font-size: 230px;
        font-weight: 800;

    }
}
</style>
