<template>
    <div>
        <el-breadcrumb
            separator-class="el-icon-arrow-right"
            class="breadcrumb-move"
        >
            <el-breadcrumb-item :to="{ path: '/welcome' }"
                >首页</el-breadcrumb-item
            >
            <el-breadcrumb-item>数据汇总</el-breadcrumb-item>
            <el-breadcrumb-item>物品总库存</el-breadcrumb-item>

        </el-breadcrumb>
        <el-card class="box-card">
            <el-table
                :data="tableData"
                style="width: 100%"
                :row-class-name="tableRowClassName"
                border
                size="mini"
                height="550"
            >
                <el-table-column
                    prop="startTime"
                    label="物品名"
                    width="180"
                    sortable
                >
                </el-table-column>
                <el-table-column
                    prop="endTime"
                    label="编号"
                    width="180"
                    sortable
                >
                </el-table-column>
                <el-table-column
                    prop="name"
                    label="所在物品柜"
                    width="180"
                    sortable
                >
                </el-table-column>
                <el-table-column
                    prop="time"
                    label="库存（克）"
                    sortable
                ></el-table-column>
                <el-table-column
                    prop="state"
                    label="状态"
                    sortable
                ></el-table-column>
            </el-table>
            <el-pagination
                @current-change="handleCurrentChange"
                :current-page="1"
                :page-size="14"
                layout="total,  prev, pager, next, jumper"
                :total="154"
                style="margin-top:20px"
                align="center"
            >
            </el-pagination>
        </el-card>
    </div>
</template>
<script>
import moment from "moment";
export default {
    data() {
        return {
            tableData: [],
        };
    },
    methods: {
        handleCurrentChange(val) {
            this.getInfo()
        },
        tableRowClassName({ row, rowIndex }) {
            if (row.state == " ") {
                return "success-row";
            } else if (row.state == "警告") {
                return "error-row";
            } else if (row.state == "未知") {
                return "warning-row";
            } else if (row.state == "正在进行") {
                //return "warning-row";
            }
            return "";
        },
        getNum(){
            let num= (Math.random()*5000).toFixed(5); 
            //console.log(num)
            return num
        },
        getRandomChemical(){
            let name=[
                "甲苯","乌洛托品", "乙醇", "乙烯", "a-氨基酸", "泡沫", "甲醛", "过氧化氢", "PP", "三线态亚甲基卡宾", "氯化氢", "硫酸"
            ]
            return name[Math.floor(Math.random() * name.length)];
        },
        getRandomName() {
            let name = [
                "1-101",
                "金属柜",
                "危险化学品柜",
                "剧毒物质",
                "教学实验柜",
            ];
            return name[Math.floor(Math.random() * name.length)];
        },
        getRandomState() {
            let state = [
                "正常",
                "未知",
                "警告",
            ];
            return state[Math.floor(Math.random() * state.length)];
        },
        getRandomTime(month) {
            // 随机生成0-11的数字
            const randomMonthNum = Math.floor(Math.random * 11);
            // 随机生成1-30数字
            const randomDateNum = Math.ceil(Math.random() * 30);
            // 随机生成1-24 数字
            const randomHourNum = Math.ceil(Math.random() * 24);
            // 随机生成1-60 数字
            const randomMinuteNum = Math.ceil(Math.random() * 60);

            return moment()
                .month(month)
                .date(randomDateNum)
                .hour(randomHourNum)
                .minutes(randomMinuteNum)
                .seconds(randomMinuteNum)
                .format("YYYY-MM-DD HH:mm:ss");
        },
        getInfo() {
            this.tableData=[]
            for (let i = 0; i < 14; i++) {
                let temp = {
                    startTime: this.getRandomChemical(),
                    endTime: Date.parse(this.getRandomTime(4)),
                    name: this.getRandomName(),
                    time: this.getNum(),
                    state: this.getRandomState(),
                };
                this.tableData.push(temp);
            }

            //console.log(temp);
        },
    },
    created() {
        this.getInfo();
    },
};
</script>
<style>
.el-table .warning-row {
    background: #e6a23cd0;
    color: black;
}

.el-table .success-row {
    background: #67c23ad0;
    color: black;
}
.el-table .error-row {
    background: #f56c6cd0;
    color: black;
}
</style>